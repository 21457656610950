import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)" >


<path d="M6060 21581 c0 -5 -19 -12 -42 -16 -103 -15 -203 -91 -253 -192 -35
-73 -39 -83 -90 -257 -25 -85 -66 -123 -190 -176 -11 -5 -31 -11 -45 -14 -14
-3 -54 -12 -90 -21 -36 -9 -83 -20 -105 -25 -304 -64 -528 -223 -665 -475 -88
-160 -100 -208 -100 -389 0 -158 8 -203 77 -436 13 -47 32 -110 40 -140 9 -30
19 -64 24 -75 4 -11 10 -33 12 -50 3 -16 10 -48 16 -70 14 -52 14 -229 0 -280
-6 -22 -13 -56 -16 -75 -2 -19 -7 -42 -10 -50 -11 -26 -34 -123 -44 -185 -31
-189 -19 -331 41 -480 5 -11 11 -27 14 -35 3 -8 19 -37 36 -65 17 -27 33 -58
36 -67 4 -10 10 -18 15 -18 5 0 9 -5 9 -10 0 -6 28 -50 63 -98 74 -101 126
-183 149 -232 9 -19 22 -48 29 -64 20 -42 19 -312 -1 -352 -18 -36 -49 -63
-73 -64 -26 0 -118 -31 -146 -49 -42 -27 -71 -85 -71 -145 0 -51 -2 -54 -34
-70 -19 -9 -44 -16 -57 -16 -34 0 -102 -23 -184 -61 -84 -40 -178 -179 -202
-299 -17 -86 -82 -151 -268 -267 -85 -53 -133 -65 -303 -78 -40 -3 -80 -9 -90
-14 -9 -5 -34 -17 -56 -27 -121 -58 -231 -260 -251 -459 -3 -33 -10 -61 -15
-63 -13 -5 -13 -209 0 -214 6 -2 15 -48 20 -103 5 -55 16 -136 25 -180 8 -44
19 -102 25 -130 5 -27 17 -84 25 -125 44 -206 55 -310 54 -545 0 -279 -36
-643 -69 -706 -6 -10 -10 -30 -10 -45 0 -15 -4 -41 -9 -58 -45 -152 -54 -311
-24 -397 10 -27 21 -49 25 -49 5 0 8 -5 8 -12 0 -7 23 -33 50 -59 44 -41 74
-61 147 -97 10 -5 28 -14 40 -20 13 -7 28 -12 34 -12 6 0 25 -6 42 -14 66 -30
289 -39 427 -18 104 15 197 36 206 45 4 4 17 7 28 7 24 0 105 27 189 64 21 9
44 16 52 16 8 0 15 5 15 10 0 6 7 10 16 10 9 0 28 6 42 14 15 8 65 33 112 56
123 60 159 86 160 118 0 6 5 12 10 12 18 0 12 128 -7 158 -62 96 -160 115
-273 51 -58 -32 -248 -119 -260 -119 -6 0 -18 -4 -28 -9 -49 -25 -95 -41 -117
-41 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -34 -10 -15 0 -48 -7 -73 -16 -35 -12
-78 -14 -184 -11 -143 3 -187 13 -241 53 l-26 20 12 69 c13 71 19 102 41 190
13 51 18 77 47 245 34 199 33 915 -2 1070 -9 41 -20 98 -25 127 -4 28 -11 57
-15 63 -7 11 -17 65 -35 185 -5 33 -14 78 -19 100 -18 67 -14 342 5 400 42
127 50 135 133 135 37 0 93 7 124 15 32 8 67 15 77 15 57 0 352 188 466 297
49 47 124 167 124 199 0 13 5 26 10 29 6 3 10 15 10 25 0 11 7 37 16 59 12 31
24 42 57 55 24 9 57 16 74 16 18 0 34 4 37 9 3 4 20 11 37 15 103 20 211 113
248 214 13 34 29 62 36 62 13 0 99 46 132 70 11 8 36 38 55 65 33 46 42 64 78
150 22 53 33 137 33 250 0 179 -18 281 -69 390 -13 28 -24 56 -24 63 0 6 -3
12 -8 12 -4 0 -16 17 -27 38 -11 20 -24 42 -28 47 -19 25 -138 198 -170 247
-19 30 -49 85 -66 122 -29 62 -31 76 -31 175 0 67 6 126 15 157 8 27 15 61 15
76 0 15 5 30 10 33 6 3 10 19 10 35 0 16 6 47 14 67 48 126 32 492 -25 605 -5
10 -9 26 -9 35 0 10 -6 35 -14 58 -8 22 -19 60 -26 85 -7 25 -18 60 -26 78 -8
18 -14 45 -14 61 0 15 -3 31 -7 35 -11 10 -43 175 -43 220 0 41 29 149 42 154
5 2 8 12 8 22 0 10 4 22 10 25 5 3 17 22 27 42 38 81 178 194 288 233 60 21
104 34 140 41 69 13 138 29 169 38 92 27 142 45 150 52 6 5 16 9 23 9 42 0
222 142 260 205 28 45 73 148 73 165 0 10 4 21 9 24 4 3 11 24 15 46 3 23 10
49 15 58 5 9 13 30 17 46 6 21 18 32 39 38 39 10 110 0 155 -22 41 -21 177
-151 236 -225 21 -27 41 -52 44 -55 3 -3 32 -36 65 -75 76 -88 99 -112 155
-155 25 -19 47 -38 48 -42 2 -5 10 -8 17 -8 7 0 26 -6 42 -14 84 -42 198 -61
310 -52 258 20 242 20 286 -2 64 -32 133 -102 255 -257 180 -228 254 -302 342
-341 97 -44 114 -48 203 -47 127 0 187 32 231 122 59 124 -65 265 -206 232
-69 -16 -116 24 -288 249 -36 46 -117 136 -180 200 -113 113 -157 144 -275
189 -34 13 -175 17 -370 11 -171 -5 -190 5 -321 167 -73 90 -144 172 -194 227
-113 122 -277 225 -387 241 -24 4 -43 11 -43 16 0 5 -38 9 -85 9 -47 0 -85 -4
-85 -9z"/>
<path d="M8485 19421 c-62 -11 -145 -59 -193 -109 -38 -40 -61 -80 -87 -147
-9 -27 -38 -232 -65 -480 -5 -44 -16 -136 -25 -205 -9 -69 -20 -163 -25 -210
-9 -93 -25 -240 -40 -365 -5 -44 -16 -143 -24 -220 -15 -141 -23 -217 -51
-510 -8 -88 -20 -200 -25 -250 -8 -81 -22 -229 -50 -560 -16 -186 -39 -485
-45 -590 -19 -351 124 -607 390 -701 94 -33 270 -23 390 23 22 8 49 19 60 23
11 3 58 26 105 50 258 131 512 330 806 631 186 191 302 343 374 494 31 64 34
78 34 160 -1 78 -5 100 -33 168 -66 155 -202 336 -428 564 -81 82 -102 93
-177 93 -95 0 -167 -75 -166 -174 1 -58 22 -92 125 -198 194 -200 325 -379
327 -448 3 -95 -341 -484 -642 -726 -41 -33 -91 -71 -110 -84 -19 -13 -37 -27
-40 -31 -9 -12 -169 -108 -237 -143 -99 -50 -160 -69 -230 -70 -54 -1 -65 2
-99 30 -21 17 -52 58 -68 90 -29 58 -30 63 -29 199 1 77 9 221 17 320 9 99 20
236 26 305 15 179 39 439 50 550 25 244 32 312 41 410 4 58 15 168 24 245 9
77 20 181 25 230 5 50 16 153 25 230 9 77 20 181 25 230 5 50 17 146 26 215 9
69 19 159 24 200 15 148 32 286 44 350 12 65 12 65 44 63 57 -2 182 -119 348
-325 119 -147 138 -162 218 -162 83 0 129 29 161 103 18 41 19 89 2 130 -7 17
-52 80 -100 140 -174 220 -344 378 -464 432 -67 31 -183 44 -258 30z"/>
<path d="M6870 17234 c-234 -50 -507 -208 -814 -471 -175 -151 -397 -380 -496
-512 -14 -18 -34 -45 -46 -59 -84 -110 -154 -264 -154 -344 0 -164 155 -416
432 -700 103 -106 151 -138 208 -138 60 0 140 53 160 105 13 34 13 106 0 140
-5 14 -58 77 -117 138 -163 170 -268 305 -317 408 -15 33 -15 37 4 76 66 138
376 476 611 668 58 47 116 92 139 107 14 10 51 35 84 58 32 22 60 40 62 40 2
0 27 15 56 33 103 63 241 117 300 117 39 0 100 -32 131 -69 14 -17 35 -60 47
-97 20 -59 22 -81 17 -193 -5 -121 -16 -273 -37 -506 -5 -60 -16 -191 -25
-290 -8 -99 -20 -223 -25 -275 -5 -52 -17 -167 -25 -255 -28 -287 -34 -349
-46 -455 -11 -102 -16 -144 -39 -375 -6 -60 -15 -134 -20 -164 -6 -30 -10 -75
-10 -99 0 -25 -4 -72 -10 -106 -10 -62 -26 -189 -40 -326 -5 -41 -16 -133 -25
-205 -8 -71 -18 -155 -22 -185 -6 -61 -26 -92 -51 -83 -70 26 -200 153 -367
357 -119 145 -172 170 -271 126 -62 -29 -97 -86 -97 -161 0 -62 5 -72 102
-194 84 -105 108 -132 206 -230 166 -167 233 -212 356 -240 69 -15 81 -15 151
0 93 19 152 50 211 113 74 80 93 140 122 382 8 74 20 171 25 215 6 44 17 139
25 210 9 72 20 166 25 210 5 44 17 148 25 230 9 83 20 186 25 230 10 78 31
284 51 475 5 52 14 142 20 200 6 58 14 150 19 205 5 55 16 172 25 260 9 88 20
214 25 280 6 66 17 206 25 310 24 298 30 479 16 543 -44 203 -119 328 -254
421 -121 82 -251 106 -397 75z"/>
<path d="M13769 13265 c-62 -17 -86 -32 -140 -86 -39 -40 -47 -54 -53 -100 -8
-60 -25 -134 -37 -164 -4 -11 -11 -43 -14 -71 -12 -93 22 -94 205 -6 154 75
240 168 240 262 0 58 -15 93 -57 131 -22 20 -91 55 -97 48 -1 -1 -22 -7 -47
-14z"/>
<path d="M15219 13266 c-44 -13 -88 -50 -111 -93 -22 -38 -23 -131 -3 -188 17
-47 19 -122 5 -161 -14 -37 1 -46 67 -40 75 7 172 72 240 163 15 21 30 40 33
43 33 33 56 134 40 176 -17 43 -80 81 -173 103 -54 13 -50 13 -98 -3z"/>
<path d="M5439 12946 c-3 -4 -62 -11 -130 -16 -68 -5 -162 -16 -209 -25 -47
-9 -101 -18 -120 -21 -31 -4 -82 -17 -176 -45 -17 -5 -39 -9 -50 -9 -36 0
-111 -56 -131 -97 -22 -46 -23 -119 -3 -159 40 -78 110 -106 217 -85 32 7 76
15 98 20 23 4 45 10 51 14 13 7 104 25 194 37 36 5 92 13 125 19 96 17 503 14
610 -5 108 -19 210 -45 290 -74 76 -28 235 -115 243 -132 2 -5 10 -8 17 -8 7
0 15 -3 17 -7 2 -5 25 -26 52 -47 66 -53 127 -155 143 -236 2 -14 8 -34 12
-45 14 -34 34 -117 42 -175 4 -30 15 -104 24 -165 17 -113 25 -199 44 -465 6
-85 16 -182 21 -216 6 -33 10 -96 10 -139 0 -43 4 -92 9 -109 9 -28 22 -94 38
-183 3 -18 11 -38 19 -44 8 -6 14 -16 14 -21 0 -20 90 -98 130 -114 8 -3 24
-10 35 -14 89 -37 129 -41 360 -37 260 4 538 23 575 39 63 27 94 46 129 78 53
50 126 154 163 233 4 9 13 26 18 37 25 50 6 140 -39 185 -46 45 -53 48 -126
48 -68 0 -74 -2 -109 -34 -21 -18 -49 -58 -64 -87 -14 -29 -41 -70 -60 -92
l-34 -40 -116 0 c-64 0 -144 -5 -177 -10 -33 -6 -122 -11 -197 -11 -168 0
-198 10 -198 69 0 20 -5 55 -11 77 -9 38 -17 142 -38 481 -5 78 -12 147 -16
153 -3 7 -11 62 -15 124 -12 145 -28 275 -36 289 -4 5 -10 35 -14 66 -5 32
-16 84 -25 117 -9 33 -19 71 -21 85 -3 14 -12 39 -20 57 -8 17 -14 36 -14 42
0 6 -4 19 -9 29 -5 9 -17 35 -27 57 -11 22 -21 42 -24 45 -3 3 -12 18 -20 34
-33 64 -211 224 -325 293 -64 39 -244 123 -262 123 -12 0 -25 5 -28 10 -3 6
-13 10 -22 10 -8 0 -29 6 -46 14 -18 8 -45 17 -62 19 -16 3 -61 13 -100 22
-38 9 -110 20 -160 26 -113 12 -458 23 -466 15z"/>
<path d="M10870 12740 c-8 -5 -28 -10 -44 -10 -27 0 -113 -24 -148 -41 -10 -5
-25 -9 -34 -9 -9 0 -30 -7 -47 -16 -18 -9 -39 -20 -47 -24 -185 -86 -381 -273
-475 -452 -11 -21 -22 -38 -25 -38 -3 0 -14 -22 -25 -50 -11 -27 -23 -50 -27
-50 -4 0 -8 -10 -8 -22 0 -13 -7 -32 -15 -42 -8 -11 -15 -27 -15 -37 0 -15
-29 -45 -101 -104 -85 -71 -105 -80 -96 -45 7 27 -9 25 -36 -3 -23 -24 -66
-60 -137 -117 -24 -19 -48 -40 -54 -45 -6 -6 -31 -26 -56 -45 -25 -19 -47 -37
-50 -40 -14 -16 -152 -109 -196 -133 -54 -28 -112 -35 -132 -15 -16 16 -16
206 -1 245 7 15 15 46 20 68 4 22 15 69 24 105 26 106 30 219 10 281 -47 148
-98 199 -200 199 -107 0 -207 -64 -403 -257 -149 -147 -204 -194 -277 -238
-60 -36 -98 -44 -111 -23 -14 22 -4 90 22 146 91 200 98 320 26 405 -19 22
-74 22 -110 0 -36 -23 -112 -104 -112 -120 0 -7 -4 -13 -9 -13 -5 0 -11 -8
-15 -17 -3 -10 -10 -25 -15 -33 -13 -21 -56 -119 -71 -160 -7 -19 -19 -48 -25
-65 -7 -16 -18 -46 -25 -65 -7 -19 -19 -48 -25 -65 -20 -48 -57 -167 -66 -210
-4 -22 -13 -65 -19 -95 -25 -117 4 -204 97 -290 87 -82 146 -96 219 -51 27 16
87 82 177 191 11 14 32 39 46 56 14 17 46 54 71 84 25 29 55 64 67 77 11 13
36 43 55 65 57 71 211 223 246 244 l34 19 32 -37 c50 -58 86 -192 86 -326 1
-87 22 -160 64 -220 28 -41 105 -102 128 -102 8 0 20 -6 27 -12 8 -9 47 -13
121 -13 74 0 113 4 122 13 6 6 18 12 26 12 19 0 164 97 217 145 22 20 59 52
83 72 41 35 52 53 32 53 -5 0 -10 -7 -10 -15 0 -8 -6 -15 -14 -15 -8 0 -16 -6
-19 -12 -4 -10 -7 -10 -17 -1 -11 10 -6 21 26 55 71 78 242 238 254 238 13 0
3 -43 -11 -48 -5 -2 -9 -11 -9 -19 0 -9 6 -13 14 -10 19 8 26 -14 26 -78 0
-30 6 -69 14 -87 8 -18 21 -49 30 -70 18 -41 96 -114 147 -139 63 -31 124 -44
200 -44 81 1 133 13 219 54 131 61 316 220 492 421 31 36 80 90 110 122 76 80
117 88 89 16 -5 -13 -21 -63 -36 -113 -15 -49 -31 -101 -35 -115 -5 -14 -16
-52 -25 -85 -9 -33 -22 -78 -28 -100 -7 -22 -12 -96 -12 -165 0 -119 1 -127
29 -175 86 -150 272 -233 434 -194 26 7 51 16 57 20 5 5 15 9 22 9 15 0 83 32
168 80 33 18 75 41 92 51 18 10 90 60 160 111 115 83 151 111 187 143 6 6 36
30 66 55 30 25 60 50 65 56 6 5 39 35 75 65 36 30 70 59 76 64 66 58 131 113
148 124 12 8 23 18 26 21 10 12 165 137 193 155 17 10 32 14 37 9 8 -8 -8 -76
-30 -129 -24 -54 -37 -318 -20 -384 36 -139 129 -234 266 -274 80 -22 298 -7
328 23 6 6 18 10 28 10 10 0 26 4 36 10 9 5 60 30 112 54 113 54 184 94 262
147 32 21 60 39 63 39 15 0 356 255 363 273 2 4 10 7 17 7 7 0 15 3 17 8 2 4
28 28 58 52 30 25 59 50 66 55 6 6 42 37 79 70 38 33 90 78 115 101 25 22 56
50 70 62 13 12 29 21 35 19 12 -5 11 -16 -10 -122 -9 -44 -20 -105 -25 -135
-5 -30 -17 -89 -26 -130 -8 -41 -19 -100 -24 -130 -5 -30 -16 -91 -25 -135 -9
-44 -20 -102 -25 -130 -11 -62 -28 -140 -41 -181 -5 -17 -9 -44 -9 -60 0 -17
-7 -39 -15 -50 -8 -10 -15 -31 -15 -46 0 -15 -7 -33 -15 -42 -9 -8 -14 -19
-13 -23 4 -10 -39 -53 -52 -53 -6 0 -23 -11 -38 -25 -15 -14 -30 -25 -34 -25
-10 0 -172 -102 -179 -114 -4 -5 -15 -6 -24 -2 -13 5 -16 2 -11 -10 5 -13 1
-16 -24 -12 -26 4 -29 2 -24 -15 5 -16 3 -17 -15 -8 -12 7 -21 8 -21 2 0 -5
-9 -13 -21 -16 -12 -4 -32 -16 -44 -27 -13 -11 -33 -26 -44 -32 -24 -13 -141
-95 -158 -110 -32 -29 -43 -36 -52 -36 -5 0 -11 -4 -13 -9 -1 -4 -55 -46 -118
-91 -126 -92 -264 -195 -294 -222 -15 -14 -17 -20 -7 -32 11 -13 20 -10 69 26
32 23 63 48 70 55 7 6 71 53 143 104 72 50 133 98 136 105 3 8 15 14 27 13 11
0 15 -3 9 -6 -15 -5 -18 -33 -4 -33 5 0 11 4 13 9 2 4 39 33 83 64 44 31 85
60 90 65 6 5 24 17 40 28 17 10 39 27 49 36 11 10 23 18 27 18 4 0 14 6 21 13
8 6 21 17 31 22 47 28 276 187 295 205 42 39 81 9 41 -31 -17 -17 -19 -23 -8
-30 18 -11 2 -40 -29 -54 -12 -5 -22 -13 -22 -17 0 -5 -7 -8 -15 -8 -8 0 -15
-4 -15 -10 0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 15 10 27 10 17 0 19 -4 13
-27 -3 -16 -9 -45 -12 -66 -3 -20 -9 -40 -14 -43 -5 -3 -9 -19 -9 -35 0 -16
-7 -46 -15 -66 -8 -19 -15 -50 -15 -69 0 -18 -4 -36 -10 -39 -5 -3 -10 -20
-10 -36 0 -16 -7 -46 -15 -66 -8 -19 -15 -44 -15 -55 0 -22 -27 -114 -41 -140
-5 -10 -9 -28 -9 -41 0 -44 -13 -47 -220 -47 -297 0 -486 -40 -679 -142 -172
-91 -310 -231 -373 -378 -33 -76 -38 -93 -53 -163 -25 -118 -15 -257 24 -362
68 -180 255 -350 493 -447 108 -44 110 -36 -41 -263 -75 -113 -79 -117 -106
-111 -11 3 -41 10 -67 16 -86 21 -196 -12 -262 -77 -88 -88 -108 -208 -61
-349 14 -41 25 -80 25 -87 0 -18 -138 -167 -155 -167 -7 0 -16 17 -20 37 -9
51 -27 83 -71 127 -72 72 -126 78 -334 41 -206 -36 -302 -9 -356 102 l-28 58
-1 340 0 341 97 136 c54 75 103 142 111 149 7 8 32 39 54 69 23 30 56 71 73
90 18 19 57 64 88 100 121 140 696 697 782 757 12 8 34 28 49 42 27 27 188
160 256 211 18 14 59 45 91 70 33 25 64 50 69 56 6 6 53 42 105 79 52 38 104
77 114 87 11 10 23 18 26 18 4 0 20 11 36 25 16 14 37 28 47 31 9 3 17 10 17
15 0 5 6 9 14 9 8 0 16 4 18 8 2 5 26 23 53 42 28 18 52 37 55 40 3 4 20 15
38 25 17 11 32 23 32 27 0 4 6 8 13 8 15 1 62 39 55 46 -3 3 -12 -1 -20 -8
-16 -15 -47 -35 -68 -45 -8 -4 -17 -9 -20 -13 -3 -3 -36 -27 -75 -52 -38 -26
-80 -56 -92 -67 -12 -12 -24 -21 -26 -21 -2 0 -41 -25 -87 -55 -68 -45 -90
-54 -112 -50 -20 5 -30 2 -34 -9 -3 -9 -10 -16 -16 -16 -5 0 -21 -11 -36 -25
-15 -14 -32 -25 -39 -25 -6 0 -13 -4 -15 -8 -1 -5 -30 -28 -63 -52 -33 -24
-69 -52 -80 -63 -11 -10 -35 -27 -52 -38 -18 -12 -33 -25 -33 -30 0 -5 -6 -9
-14 -9 -8 0 -16 -3 -18 -7 -3 -9 -76 -68 -157 -129 -31 -23 -65 -50 -76 -60
-11 -11 -52 -45 -90 -76 -39 -31 -86 -71 -105 -88 -38 -33 -55 -38 -46 -15 3
8 3 15 0 15 -10 0 -99 -64 -110 -78 -6 -9 -13 -11 -17 -5 -6 10 90 106 208
208 38 33 76 67 85 75 9 8 37 32 63 53 78 63 114 101 110 115 -6 17 -26 15
-49 -6 -45 -42 -221 -172 -232 -172 -22 0 -12 17 31 56 159 141 208 189 198
192 -8 3 -113 -67 -131 -87 -7 -9 -87 -78 -155 -136 -32 -27 -77 -67 -99 -88
-23 -22 -70 -64 -105 -95 -36 -32 -79 -72 -96 -89 -18 -18 -37 -33 -42 -33 -6
0 -12 33 -15 73 -5 79 -15 103 -68 160 -64 70 -126 87 -322 87 -80 0 -153 3
-162 6 -14 5 -16 37 -16 248 0 132 -5 302 -10 376 -13 179 -34 234 -113 307
-72 67 -126 86 -237 87 -86 1 -101 -2 -152 -28 -112 -56 -172 -149 -188 -288
-6 -46 -10 -218 -10 -384 0 -220 -3 -303 -12 -312 -7 -7 -40 -12 -78 -12 -196
0 -319 -97 -320 -251 0 -133 86 -225 235 -249 33 -5 81 -10 106 -10 28 0 51
-6 58 -14 9 -11 12 -201 10 -774 -1 -418 1 -764 5 -770 7 -12 -10 -132 -24
-167 -24 -64 -50 -229 -56 -362 l-6 -113 105 0 104 0 7 40 c4 23 12 40 19 40
8 0 42 -23 76 -50 62 -50 113 -77 181 -98 19 -6 51 -16 70 -22 70 -22 306 -32
463 -21 83 6 169 16 191 22 103 27 150 43 196 65 42 20 65 24 144 24 l94 0 59
63 c33 34 85 89 115 122 49 52 58 57 64 41 10 -25 -7 -51 -93 -140 -40 -42
-73 -78 -73 -81 0 -3 41 -5 92 -5 l92 0 52 55 c56 59 67 63 102 40 94 -66 312
-150 480 -184 231 -47 689 -30 912 34 30 9 64 19 75 22 40 10 151 58 187 80
21 13 40 23 43 23 3 0 32 19 63 42 194 141 292 313 328 575 14 101 14 123 1
208 -38 238 -192 415 -483 556 -67 32 -127 59 -133 59 -6 0 -19 4 -29 10 -28
16 -189 63 -358 104 -120 30 -154 42 -154 55 0 5 18 49 40 96 22 47 40 90 40
95 0 4 11 30 24 57 13 26 36 80 51 118 47 120 65 155 79 155 14 0 91 -76 175
-175 130 -151 232 -200 369 -175 83 16 126 37 174 87 51 53 68 99 68 185 0
212 -183 412 -490 537 -58 24 -108 45 -111 47 -6 4 12 81 29 124 5 14 15 45
22 70 7 25 18 63 26 85 8 23 14 48 14 58 0 9 4 25 9 35 8 16 19 53 41 142 5
19 16 62 25 95 9 33 20 76 25 95 5 19 16 62 25 95 9 33 20 76 25 95 5 19 16
62 25 95 9 33 19 74 22 90 6 36 44 79 96 108 20 11 37 24 37 28 0 4 10 11 22
14 11 4 36 21 55 38 19 17 43 38 54 47 11 8 33 27 49 43 16 15 34 27 40 27 6
0 8 5 5 10 -6 10 -85 -44 -93 -62 -2 -5 -8 -8 -14 -8 -5 0 -26 -12 -46 -27
-20 -16 -41 -31 -47 -35 -5 -4 -26 -20 -45 -36 -19 -16 -43 -31 -52 -35 -23
-8 -33 22 -12 38 12 8 15 20 11 35 -3 13 2 38 12 59 10 20 22 51 26 69 4 18
13 36 21 39 13 5 12 8 -2 24 -14 16 -15 25 -7 53 6 19 7 39 3 46 -4 7 -3 28 1
49 10 38 24 130 38 248 5 40 13 78 17 84 4 7 11 42 15 78 16 136 28 222 34
231 3 5 10 42 16 82 5 40 13 102 19 138 11 79 0 127 -37 162 -46 43 -88 58
-161 60 -77 1 -92 -4 -92 -32 0 -10 -4 -22 -10 -25 -5 -3 -10 -20 -10 -36 0
-16 -7 -46 -15 -66 -8 -19 -15 -43 -15 -53 0 -9 -28 -42 -61 -74 -34 -31 -59
-59 -56 -62 2 -3 18 9 35 26 35 35 52 38 52 10 0 -11 -5 -20 -11 -20 -8 0 -8
-5 1 -15 24 -29 -6 -70 -98 -132 -48 -31 -89 -60 -92 -63 -11 -11 -60 -33 -65
-28 -3 3 31 39 75 81 54 52 78 81 74 92 -3 8 -1 15 5 15 6 0 11 7 11 15 0 8
-4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -5 -15 -11 -15 -12 0 -40 -20 -104 -75
-22 -19 -78 -64 -125 -100 -47 -37 -94 -73 -104 -82 -11 -8 -53 -40 -95 -69
-42 -30 -81 -58 -88 -64 -7 -5 -15 -10 -19 -10 -4 0 -15 -6 -23 -14 -9 -8 -27
-16 -41 -18 -39 -5 -35 24 7 50 59 35 78 47 83 52 3 3 29 21 57 40 29 19 53
38 53 43 0 4 6 7 13 7 15 0 82 58 75 66 -3 3 -13 -2 -21 -11 -9 -8 -20 -15
-25 -15 -6 0 -12 -3 -14 -8 -3 -6 -240 -168 -289 -197 -9 -5 -76 -47 -148 -92
-72 -46 -134 -83 -137 -83 -3 0 -20 -10 -37 -22 -18 -13 -41 -28 -52 -34 -11
-6 -42 -23 -70 -39 -27 -15 -97 -52 -155 -82 -58 -30 -109 -58 -115 -63 -5 -4
-26 -13 -46 -19 -47 -15 -105 7 -120 47 -12 29 -5 141 10 170 5 9 17 37 26 62
38 100 136 283 240 450 41 67 75 124 75 126 0 3 16 29 35 59 57 90 65 117 50
161 -19 56 -73 106 -134 126 -55 18 -86 13 -181 -29 -48 -22 -149 -130 -185
-198 -16 -32 -35 -60 -42 -62 -7 -3 -13 -17 -13 -32 0 -15 -13 -42 -30 -61
-18 -22 -29 -47 -30 -66 0 -35 -22 -58 -149 -154 -40 -30 -84 -65 -99 -78 -43
-36 -142 -104 -142 -97 0 19 34 66 69 97 46 40 50 48 22 48 -40 0 -30 20 41
81 100 86 103 89 85 89 -9 0 -17 -3 -19 -8 -2 -4 -32 -27 -68 -51 -147 -100
-180 -127 -168 -139 9 -9 1 -22 -32 -52 -24 -22 -46 -40 -50 -40 -4 0 -23 -13
-41 -30 -19 -16 -49 -37 -66 -47 -18 -10 -33 -21 -33 -25 0 -5 -6 -8 -14 -8
-8 0 -16 -3 -18 -7 -5 -13 -68 -45 -68 -35 0 9 135 122 145 122 4 0 89 66 123
96 2 1 -5 0 -17 -3 -12 -3 -21 -9 -21 -13 0 -4 -8 -10 -17 -14 -21 -6 -56 -28
-63 -38 -4 -4 -22 -10 -40 -14 -26 -4 -31 -3 -20 5 8 5 12 13 8 16 -6 6 -76
-34 -105 -59 -7 -6 -35 -27 -64 -46 -28 -19 -70 -50 -92 -69 -23 -18 -52 -38
-64 -44 -13 -6 -23 -14 -23 -19 0 -4 -7 -8 -15 -8 -8 0 -15 -4 -15 -9 0 -5 -8
-12 -18 -15 -10 -4 -27 -14 -38 -24 -16 -15 -106 -74 -189 -125 -11 -7 -43
-27 -72 -44 -30 -18 -57 -33 -62 -33 -4 0 -29 -11 -55 -25 -26 -14 -58 -25
-72 -25 -20 0 -24 5 -24 30 0 34 18 117 31 142 5 10 9 26 9 35 0 10 7 31 15
47 8 15 15 37 15 47 0 10 4 20 9 23 5 3 11 18 14 33 6 32 52 160 67 185 5 10
10 27 10 37 0 11 5 23 10 26 6 3 10 14 10 23 0 9 7 30 15 46 8 15 15 37 15 47
0 10 4 20 9 24 5 3 13 20 17 37 3 18 10 39 14 48 5 8 16 41 25 73 39 131 -11
218 -148 261 -14 4 -17 12 -12 28 10 30 10 25 1 93 -14 103 -90 213 -177 257
-60 30 -103 46 -139 53 -58 12 -324 16 -340 5z m347 -299 c76 -20 213 -114
213 -145 0 -8 -24 -21 -52 -30 -50 -16 -217 -93 -228 -105 -3 -4 -38 -28 -79
-56 -41 -27 -91 -63 -111 -80 -20 -16 -55 -45 -77 -63 -23 -18 -54 -45 -70
-58 -15 -14 -50 -44 -77 -67 -26 -23 -58 -50 -70 -61 -11 -10 -41 -35 -66 -55
-25 -20 -47 -39 -50 -42 -8 -8 -112 -82 -140 -98 -67 -39 -113 -61 -127 -61
-50 0 -68 71 -38 146 29 73 68 157 75 164 3 3 14 22 25 43 11 20 23 37 27 37
5 0 8 5 8 11 0 43 293 361 373 404 20 11 37 23 37 27 0 5 5 8 10 8 6 0 27 10
48 23 20 13 62 31 92 40 30 9 69 21 85 26 36 12 134 8 192 -8z m1973 -240 c0
-11 -4 -23 -10 -26 -5 -3 -10 -13 -10 -22 0 -8 -9 -28 -20 -43 -11 -16 -20
-32 -19 -37 2 -8 -2 -20 -21 -68 -9 -24 -39 -101 -50 -130 -7 -18 -27 -69 -50
-130 -26 -66 -40 -113 -40 -132 0 -12 -4 -24 -9 -27 -5 -3 -12 -32 -16 -63 -4
-32 -9 -62 -12 -67 -20 -32 -32 117 -14 178 5 17 17 58 26 91 18 62 39 117 55
147 6 10 10 23 10 30 0 17 54 124 73 146 8 9 17 29 21 44 8 34 45 98 57 98 5
0 9 7 9 15 0 8 5 15 10 15 6 0 10 -9 10 -19z m-2047 -157 c-8 -13 -18 -24 -22
-24 -3 0 -29 -21 -56 -48 -28 -26 -86 -78 -130 -117 -44 -38 -82 -73 -85 -76
-14 -15 -174 -141 -234 -184 -87 -61 -189 -115 -219 -114 -14 0 -17 3 -8 6 43
17 268 196 446 355 61 54 81 71 115 97 19 14 44 34 55 43 99 83 174 117 138
62z m400 -77 c-19 -56 -41 -115 -48 -132 -7 -16 -18 -46 -25 -65 -7 -19 -19
-48 -25 -65 -20 -49 -57 -167 -66 -210 -19 -97 -62 -245 -71 -245 -17 0 6 247
25 266 4 4 7 21 7 38 1 17 7 42 15 56 8 14 14 35 15 48 0 12 4 30 9 40 5 9 17
37 26 62 10 25 21 54 25 65 5 11 27 61 51 110 83 173 118 191 62 32z m-2745
33 c43 0 22 -17 -30 -24 -67 -9 -121 -48 -224 -163 -44 -48 -117 -126 -163
-173 -72 -73 -83 -81 -77 -56 4 17 13 35 20 40 7 6 42 47 77 91 77 96 177 198
250 254 46 34 59 40 89 36 19 -3 45 -5 58 -5z m1102 -206 c0 -18 -177 -194
-258 -256 -29 -22 -56 -37 -60 -33 -4 4 -1 10 6 12 6 3 12 10 12 16 0 26 260
267 298 276 1 1 2 -6 2 -15z m4450 -84 c0 -38 -358 -311 -407 -310 -17 1 -17
2 2 16 11 8 29 22 40 32 11 9 40 33 65 52 25 19 54 43 64 52 11 10 23 18 27
18 4 0 14 6 21 13 23 24 158 117 168 117 6 0 10 5 10 10 0 6 2 10 5 10 3 0 5
-4 5 -10z m587 -413 c-7 -99 -23 -206 -36 -240 -6 -16 -11 -40 -11 -54 0 -15
-4 -40 -9 -57 -11 -36 -29 -112 -41 -176 -4 -25 -16 -74 -25 -110 -16 -67 -26
-111 -44 -193 -6 -27 -14 -53 -18 -60 -4 -6 -10 -29 -14 -52 -15 -78 -31 -140
-40 -150 -5 -5 -9 -20 -9 -33 0 -25 -26 -116 -41 -144 -5 -10 -9 -27 -9 -39 0
-22 -26 -110 -41 -141 -5 -10 -9 -26 -9 -37 0 -21 -32 -37 -51 -25 -11 6 -9
34 9 109 24 102 35 141 43 157 5 10 9 27 9 39 0 12 7 42 15 68 7 25 19 66 25
91 6 25 17 68 25 95 8 28 16 61 19 75 2 14 12 52 21 85 9 33 19 74 21 90 3 17
8 37 11 45 4 8 13 40 20 70 19 77 29 116 48 185 9 33 20 80 25 105 18 91 36
159 43 166 4 4 7 22 7 39 0 18 7 52 15 75 8 23 15 57 15 75 0 17 3 35 7 39 15
15 24 -29 20 -97z m-2197 -1825 c0 -11 -38 -42 -52 -42 -6 0 -1 11 12 25 23
25 40 32 40 17z m1387 -108 c3 -8 1 -20 -6 -27 -6 -6 -11 -19 -11 -28 0 -10
-6 -30 -14 -46 -8 -15 -25 -50 -36 -78 -42 -96 -53 -121 -92 -205 -81 -172
-75 -167 -151 -129 -151 76 -216 182 -181 299 38 129 210 227 402 229 66 1 84
-2 89 -15z m193 -34 c0 -16 -42 -134 -51 -144 -5 -6 -9 -16 -9 -23 0 -7 -11
-36 -25 -65 -14 -28 -25 -55 -25 -60 0 -4 -11 -31 -25 -60 -14 -28 -25 -55
-25 -60 0 -4 -11 -31 -25 -60 -14 -28 -25 -57 -25 -64 0 -17 -15 -25 -33 -18
-16 6 -13 26 13 89 7 17 16 39 20 50 30 79 43 112 51 127 5 10 9 22 9 27 0 6
18 49 40 96 22 47 40 89 40 92 0 25 37 83 52 83 10 0 18 -4 18 -10z m40 -46
c0 -14 -6 -38 -14 -52 -15 -30 -31 -68 -40 -94 -3 -10 -10 -18 -16 -18 -13 0
-13 47 0 55 6 3 10 16 10 29 0 12 7 31 15 42 8 10 15 29 15 42 0 13 6 22 15
22 9 0 15 -10 15 -26z m-1926 7 c3 -5 -13 -28 -35 -52 -199 -212 -323 -348
-343 -375 -30 -40 -37 -4 -34 197 l3 194 65 2 c102 4 278 23 302 33 30 12 36
12 42 1z m1833 -213 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-2020 -130 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-36 -41
c-25 -26 -28 -6 -4 23 11 12 19 16 22 9 2 -6 -6 -21 -18 -32z m1579 -688 c0
-16 -43 -103 -52 -107 -5 -2 -8 -12 -8 -22 0 -9 -7 -23 -15 -30 -8 -7 -15 -17
-15 -23 0 -5 -11 -25 -25 -43 -14 -18 -25 -35 -25 -38 0 -10 -111 -186 -116
-186 -3 0 -3 19 1 43 6 38 6 40 -8 23 -17 -23 -37 -13 -37 17 1 27 36 71 50
62 5 -3 10 -17 10 -31 0 -35 13 -30 33 14 9 20 26 51 36 67 31 52 84 148 110
200 24 48 37 65 54 65 4 0 7 -5 7 -11z m60 -24 c0 -8 -10 -31 -21 -52 -12 -21
-31 -55 -41 -75 -11 -21 -24 -38 -29 -38 -5 0 -9 -9 -9 -20 0 -11 -3 -20 -8
-20 -4 0 -12 -9 -17 -19 -41 -80 -60 -111 -67 -111 -4 0 -8 -7 -8 -17 0 -9
-10 -30 -23 -47 -13 -17 -26 -39 -30 -48 -3 -10 -14 -18 -22 -18 -21 0 -12 33
21 77 13 17 24 34 24 38 0 3 21 40 48 83 26 42 56 95 67 117 11 22 23 42 28
43 4 2 7 10 7 17 0 8 12 35 27 60 27 44 53 59 53 30z m280 -53 c255 -70 401
-129 467 -188 53 -49 85 -119 91 -202 6 -92 -13 -144 -74 -206 -57 -56 -110
-88 -199 -118 -62 -20 -89 -23 -230 -23 -182 0 -279 20 -385 78 -75 41 -180
140 -180 169 0 11 15 41 34 67 18 25 68 102 111 171 67 108 92 152 149 255 16
29 39 46 56 41 8 -3 80 -23 160 -44z"/>
<path d="M16995 12740 c-11 -5 -33 -9 -49 -9 -28 -1 -114 -25 -148 -42 -10 -5
-23 -9 -30 -9 -20 0 -179 -81 -244 -125 -64 -43 -172 -146 -219 -210 -17 -22
-32 -42 -35 -45 -19 -17 -91 -138 -129 -215 -24 -50 -51 -98 -59 -107 -7 -9
-11 -22 -7 -28 4 -6 2 -16 -3 -23 -5 -7 -13 -34 -17 -62 -4 -27 -11 -56 -17
-62 -17 -21 -11 -271 9 -323 9 -25 20 -49 25 -54 4 -6 8 -16 8 -24 0 -7 18
-33 41 -57 38 -41 95 -74 179 -106 46 -18 183 -18 240 0 59 18 191 82 210 101
3 3 32 25 65 49 67 49 216 197 345 341 109 122 160 171 171 164 12 -7 11 -37
0 -66 -5 -13 -21 -63 -36 -113 -15 -49 -33 -105 -41 -123 -8 -18 -14 -40 -14
-49 0 -9 -4 -30 -9 -47 -35 -116 -41 -151 -41 -239 0 -84 4 -104 27 -153 72
-153 259 -247 429 -215 36 6 73 16 82 21 9 5 53 27 97 48 44 22 96 49 115 60
19 12 49 29 67 37 18 9 37 23 44 31 6 8 18 14 25 14 8 0 14 4 14 8 0 4 15 16
33 27 17 10 34 21 37 25 3 3 32 26 65 51 33 24 62 46 65 49 3 3 25 21 50 40
39 30 87 71 124 105 6 6 56 49 111 95 55 47 105 89 111 95 6 5 30 26 54 45 24
19 48 40 54 45 6 6 31 26 56 45 25 19 54 43 65 54 11 10 43 35 70 56 28 20 68
55 91 76 23 21 56 52 74 67 18 15 30 31 26 35 -4 3 -10 2 -14 -4 -4 -5 -27
-25 -52 -44 -25 -19 -47 -37 -50 -40 -3 -3 -23 -19 -45 -36 -33 -24 -159 -123
-225 -177 -31 -25 -65 -47 -73 -47 -14 0 3 53 21 66 30 21 62 51 62 58 0 3 -9
6 -19 6 -35 0 -31 17 12 53 65 54 131 117 123 117 -5 0 -39 -22 -77 -50 -38
-27 -71 -50 -74 -50 -3 0 -33 -20 -66 -44 -50 -36 -58 -46 -44 -53 14 -8 8
-17 -37 -54 -29 -25 -82 -65 -118 -90 -36 -24 -66 -47 -68 -51 -2 -5 -10 -8
-17 -8 -7 0 -15 -3 -17 -7 -4 -10 -68 -46 -68 -39 0 10 39 46 50 46 6 0 10 4
10 8 0 5 21 24 48 42 47 34 82 65 82 74 0 6 -33 -5 -40 -13 -3 -3 -15 -11 -26
-18 -25 -14 -39 -6 -23 14 20 23 -11 15 -47 -12 -18 -14 -37 -25 -43 -25 -6 0
-11 -4 -11 -8 0 -4 -15 -16 -32 -27 -18 -10 -42 -27 -53 -38 -11 -10 -36 -28
-55 -40 -19 -11 -37 -23 -40 -27 -10 -11 -79 -60 -85 -60 -3 0 -18 -11 -33
-25 -15 -14 -30 -25 -35 -25 -4 0 -20 -11 -35 -25 -15 -14 -32 -25 -38 -25 -6
0 -17 -6 -23 -14 -7 -8 -26 -21 -44 -30 -18 -9 -41 -22 -51 -29 -89 -56 -127
-77 -136 -77 -6 0 -29 -12 -51 -26 -24 -15 -51 -24 -67 -22 -26 3 -27 5 -24
58 2 30 10 70 18 89 8 18 14 42 14 52 0 11 4 27 9 37 5 9 22 53 36 97 15 44
33 94 40 110 6 17 20 55 30 85 10 30 22 59 26 65 5 5 9 17 9 27 0 16 19 66 41
110 5 10 9 24 9 33 0 13 13 48 43 113 6 13 11 56 12 96 0 65 -3 77 -29 110
-17 22 -50 48 -80 62 l-51 24 -1 57 c-1 64 -14 120 -40 172 -21 41 -119 141
-139 141 -8 0 -15 3 -15 8 0 7 -47 26 -115 44 -51 14 -324 20 -350 8z m343
-298 c24 -5 49 -15 55 -21 6 -6 19 -11 29 -11 10 0 18 -4 18 -8 0 -4 12 -15
28 -24 41 -25 82 -67 82 -84 0 -8 -13 -17 -30 -21 -17 -3 -33 -10 -36 -14 -3
-5 -12 -9 -20 -9 -40 0 -282 -140 -366 -212 -15 -13 -52 -43 -83 -68 -31 -25
-69 -56 -83 -70 -15 -14 -70 -61 -122 -105 -52 -44 -97 -82 -100 -86 -13 -15
-160 -119 -169 -119 -6 0 -11 -4 -11 -8 0 -10 -109 -62 -130 -62 -24 0 -50 32
-50 63 0 43 44 166 81 227 5 8 12 23 15 33 4 9 10 17 14 17 4 0 10 8 14 18 6
20 25 50 44 70 6 7 12 16 12 20 0 4 11 20 25 36 14 16 25 33 25 37 0 12 248
259 259 259 5 0 16 6 23 13 23 21 92 64 144 88 119 55 219 67 332 41z m-85
-411 c-87 -81 -224 -204 -250 -224 -15 -12 -41 -34 -58 -49 -34 -31 -139 -115
-176 -140 -13 -10 -43 -31 -66 -48 -24 -16 -47 -30 -52 -30 -5 0 -11 -4 -13
-8 -5 -12 -95 -52 -115 -51 -14 0 -15 2 -3 6 8 3 28 19 44 35 17 15 36 28 43
28 7 0 13 4 13 9 0 5 8 12 18 15 9 3 28 16 42 29 14 12 68 58 120 102 52 43
104 88 115 100 23 23 71 64 133 113 22 19 51 43 64 54 101 89 231 143 141 59z
m437 23 c0 -22 -49 -167 -61 -178 -5 -6 -9 -18 -9 -28 0 -10 -4 -26 -10 -36
-21 -38 -77 -193 -91 -252 -24 -100 -35 -139 -42 -146 -4 -4 -7 -26 -7 -48 -1
-26 -10 -56 -26 -81 l-25 -40 7 95 c7 106 38 253 70 328 8 18 14 39 14 47 0 9
4 23 9 33 4 9 15 33 23 52 9 19 23 51 32 72 9 20 16 41 16 47 0 6 5 11 10 11
6 0 10 5 10 12 0 17 33 88 41 88 4 0 10 11 13 25 7 27 26 25 26 -1z"/>
<path d="M20018 12605 c-47 -13 -105 -35 -127 -49 -22 -14 -44 -26 -49 -26
-20 0 -130 -120 -165 -180 -53 -88 -82 -189 -101 -350 -9 -74 -21 -169 -26
-210 -5 -41 -16 -133 -25 -205 -8 -71 -20 -166 -25 -210 -20 -164 -39 -333
-50 -435 -6 -58 -18 -161 -25 -230 -8 -69 -19 -172 -25 -230 -6 -58 -18 -161
-25 -230 -15 -129 -24 -216 -50 -480 -8 -85 -20 -198 -25 -250 -5 -52 -17
-171 -25 -265 -9 -93 -20 -215 -26 -270 -12 -122 -27 -300 -39 -460 -5 -66
-16 -208 -25 -315 -48 -603 -36 -756 78 -985 92 -185 260 -335 452 -404 51
-18 178 -41 228 -41 102 0 295 42 412 90 92 38 255 123 360 189 104 64 318
217 357 254 7 7 33 28 58 47 25 19 50 39 56 45 6 5 42 37 80 70 199 172 492
481 627 663 277 373 312 569 154 887 -81 165 -257 391 -484 625 -174 179 -215
204 -338 204 -67 0 -89 -4 -136 -27 -143 -69 -218 -230 -174 -373 24 -78 39
-98 169 -234 188 -195 250 -269 342 -406 35 -53 64 -103 64 -112 0 -31 -112
-188 -233 -327 -312 -356 -745 -715 -1037 -859 -140 -68 -194 -86 -262 -86
-51 0 -59 3 -82 31 -38 45 -54 79 -72 156 -14 58 -14 86 -5 188 11 112 19 222
41 525 5 69 16 204 25 300 9 96 20 225 25 285 5 61 16 182 25 270 8 88 20 210
25 270 5 61 13 146 19 190 5 44 14 134 21 200 10 112 28 276 50 475 5 47 17
150 25 230 9 80 20 183 25 230 5 47 17 148 25 225 9 77 20 176 25 220 16 132
40 325 50 410 5 44 15 121 21 170 12 107 18 135 29 135 28 0 245 -234 383
-412 118 -151 168 -182 302 -183 73 0 99 4 138 23 68 32 122 85 154 150 22 48
27 70 27 142 0 79 -2 91 -33 144 -104 185 -464 577 -632 689 -107 71 -173 100
-273 121 -103 21 -172 19 -278 -9z"/>
<path d="M18935 11990 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M14390 11909 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M12690 11809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M18810 11809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M12490 11759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M18605 11760 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5
0 -13 -4 -16 -10z"/>
<path d="M18567 11735 c-9 -8 -20 -15 -26 -15 -6 0 -11 -4 -11 -10 0 -5 3 -10
8 -10 12 1 55 40 50 46 -3 3 -13 -2 -21 -11z"/>
<path d="M15750 11680 c-19 -17 -42 -32 -52 -35 -15 -5 -34 -35 -22 -35 11 0
46 22 64 40 10 10 26 22 35 27 10 6 16 15 14 22 -2 6 -19 -2 -39 -19z"/>
<path d="M9766 11559 c-43 -44 -77 -82 -74 -84 4 -4 168 151 168 160 0 14 -22
-4 -94 -76z"/>
<path d="M15700 11480 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z"/>
<path d="M15675 11460 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M15640 11440 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14
-2 3 -11 1 -19 -4z"/>
<path d="M15605 11410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M15540 11360 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M15500 11330 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z"/>
<path d="M15475 11310 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M15438 11283 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M15335 11210 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2
0 -12 -4 -23 -9z"/>
<path d="M15300 11179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M8685 10992 c-33 -17 -37 -21 -79 -72 -15 -19 -45 -53 -65 -75 -20
-22 -52 -60 -72 -85 -20 -25 -47 -56 -58 -70 -12 -14 -31 -36 -43 -49 -24 -28
-30 -106 -10 -143 31 -56 117 -75 177 -38 30 19 45 34 118 124 18 22 44 52 58
67 15 15 33 37 41 48 9 12 30 38 49 59 19 20 41 50 48 65 32 61 4 139 -61 171
-38 19 -64 19 -103 -2z"/>
<path d="M8981 10823 c-61 -31 -84 -91 -60 -158 17 -47 155 -145 205 -145 57
0 124 70 124 130 0 41 -32 89 -81 125 -27 19 -53 35 -58 35 -5 0 -11 4 -13 10
-6 19 -82 21 -117 3z"/>
<path d="M9382 10687 c-48 -15 -74 -57 -74 -117 1 -55 19 -87 65 -114 65 -37
141 -11 178 60 19 39 20 47 9 76 -18 45 -48 78 -87 94 -37 16 -45 16 -91 1z"/>
<path d="M14380 10533 c-21 -9 -56 -43 -43 -43 6 0 16 7 23 15 7 8 19 15 26
15 8 0 14 5 14 10 0 6 -1 10 -2 9 -2 0 -10 -3 -18 -6z"/>
<path d="M13768 10223 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6405 10068 c-210 -52 -345 -109 -553 -232 -92 -55 -347 -228 -362
-245 -3 -3 -39 -33 -80 -66 -41 -33 -77 -62 -80 -65 -3 -3 -45 -40 -95 -82
-139 -118 -450 -430 -538 -540 -21 -26 -44 -53 -50 -60 -7 -7 -33 -40 -57 -73
-25 -33 -47 -62 -50 -65 -14 -13 -111 -161 -137 -210 -17 -30 -43 -89 -58
-130 -25 -66 -28 -89 -28 -190 0 -136 14 -192 88 -340 90 -181 274 -418 524
-673 133 -136 168 -161 253 -183 91 -24 176 -6 259 53 88 62 123 136 123 258
0 120 -15 148 -173 310 -198 203 -336 373 -408 502 l-35 64 26 39 c14 22 26
42 26 45 0 9 75 109 149 200 84 102 246 273 397 416 149 141 456 384 560 444
16 9 58 34 94 55 146 89 302 150 384 150 82 0 154 -101 175 -245 7 -46 10 -92
7 -102 -3 -11 -11 -91 -16 -179 -5 -87 -17 -244 -25 -349 -9 -104 -20 -242
-25 -305 -13 -167 -29 -351 -40 -460 -5 -52 -16 -171 -25 -265 -8 -93 -20
-213 -25 -265 -5 -52 -16 -165 -25 -250 -9 -85 -20 -198 -25 -250 -5 -52 -17
-158 -25 -235 -9 -77 -20 -180 -25 -230 -6 -49 -15 -130 -21 -180 -6 -49 -15
-130 -19 -180 -5 -49 -16 -151 -25 -225 -8 -74 -20 -173 -25 -220 -5 -47 -16
-143 -25 -215 -9 -71 -20 -164 -25 -205 -40 -352 -50 -410 -69 -410 -25 0
-271 257 -394 412 -160 201 -191 221 -333 222 -77 1 -97 -3 -139 -24 -66 -34
-117 -86 -149 -153 -37 -80 -37 -195 0 -270 15 -29 29 -54 33 -57 3 -3 26 -32
51 -65 24 -33 52 -69 61 -80 318 -383 526 -561 734 -630 28 -10 95 -19 150
-22 181 -9 330 45 452 164 102 100 162 230 183 403 28 213 42 334 50 405 5 44
16 139 25 210 9 72 20 164 25 205 5 41 16 140 25 220 9 80 20 183 25 230 5 47
16 148 25 225 9 77 20 181 25 230 5 50 14 131 19 180 6 50 15 133 21 185 5 52
16 156 25 230 8 75 15 152 15 173 0 20 4 67 10 104 5 38 16 149 25 248 9 99
20 223 25 275 5 52 17 181 25 285 9 105 20 233 25 285 6 52 17 194 25 315 9
121 20 265 25 320 38 381 -23 648 -198 875 -110 143 -255 238 -435 285 -67 17
-286 19 -352 3z"/>
<path d="M13325 9970 c-3 -5 1 -10 9 -10 9 0 16 -5 16 -12 0 -6 2 -9 5 -6 7 7
-5 38 -15 38 -5 0 -12 -4 -15 -10z"/>
<path d="M9105 9830 c-251 -20 -488 -100 -682 -231 -169 -114 -352 -345 -423
-534 -4 -11 -15 -38 -24 -60 -49 -124 -68 -211 -91 -405 -42 -358 19 -731 161
-987 93 -166 252 -345 389 -435 125 -82 299 -156 445 -188 30 -6 71 -16 90
-21 19 -4 108 -13 197 -19 354 -25 617 22 890 157 378 187 593 564 423 741
-38 40 -104 62 -186 62 -105 0 -133 -16 -294 -170 -143 -137 -285 -233 -423
-289 -93 -37 -320 -54 -411 -30 -208 53 -333 141 -438 304 -74 117 -127 296
-128 431 0 45 3 52 23 58 12 3 362 6 779 6 702 0 763 1 850 19 162 32 237 76
280 162 35 70 48 139 48 253 0 114 -28 280 -62 365 -6 14 -14 35 -18 46 -60
152 -139 273 -249 385 -129 132 -255 218 -426 291 -174 75 -459 110 -720 89z
m319 -504 c82 -23 121 -42 196 -97 149 -109 245 -300 266 -531 l7 -78 -647 0
-646 0 0 25 c0 44 32 192 58 265 65 185 207 341 361 398 117 43 284 51 405 18z"/>
<path d="M16195 9813 c-102 -41 -167 -119 -203 -239 -15 -54 -17 -133 -17
-904 0 -661 3 -866 14 -940 41 -287 133 -461 315 -599 111 -84 231 -136 385
-167 105 -21 366 -15 481 10 199 45 381 150 543 313 54 55 102 105 107 113 16
25 30 -3 30 -60 0 -174 73 -309 200 -368 78 -37 192 -37 270 0 109 50 165 134
187 278 16 109 19 2130 3 2243 -19 130 -39 176 -105 242 -80 80 -131 98 -257
93 -71 -3 -104 -9 -140 -27 -52 -25 -121 -89 -143 -131 -48 -94 -47 -74 -56
-800 -9 -787 -17 -889 -73 -1013 -27 -58 -96 -150 -150 -200 -129 -119 -329
-183 -493 -158 -78 12 -92 16 -147 42 -92 44 -190 183 -221 316 -39 169 -45
290 -45 983 0 711 0 716 -47 819 -22 48 -90 113 -148 142 -65 31 -226 39 -290
12z"/>
<path d="M11232 7570 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M11202 7475 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M11183 7350 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M12799 6166 c-13 -28 -51 -39 -179 -55 -124 -15 -160 -22 -160 -32 0
-18 -49 -9 -55 10 -3 11 -24 26 -45 35 -37 15 -43 14 -77 -2 -43 -19 -62 -34
-108 -79 -35 -36 -59 -42 -73 -17 -7 13 -33 15 -198 10 -104 -3 -209 -6 -234
-8 -43 -3 -45 -2 -43 22 1 14 5 37 8 53 5 17 3 33 -5 42 -11 14 -17 7 -41 -42
-27 -56 -45 -99 -76 -173 -8 -19 -18 -43 -23 -52 -6 -10 -10 -26 -10 -36 0
-10 -4 -22 -9 -28 -4 -5 -16 -31 -26 -59 -10 -27 -27 -70 -38 -93 -20 -44 -20
-44 -40 -24 -21 22 -34 117 -44 340 -5 110 -5 113 -31 125 -15 8 -34 12 -42
10 -14 -3 -51 -100 -50 -131 0 -7 -4 -20 -9 -30 -8 -16 -25 -74 -51 -172 -7
-25 -18 -63 -26 -85 -8 -23 -14 -50 -14 -62 0 -12 -4 -24 -9 -27 -4 -3 -11
-23 -15 -45 -3 -22 -9 -46 -12 -54 -3 -9 4 -25 15 -36 20 -20 21 -20 53 -2 28
15 33 23 30 51 -1 18 3 49 9 69 6 20 15 56 19 81 18 95 36 152 53 161 19 11
23 2 36 -111 13 -100 26 -159 40 -173 6 -6 11 -19 11 -29 0 -20 38 -33 67 -24
17 5 25 19 60 111 31 82 35 91 49 118 8 16 14 35 14 43 0 8 4 22 9 32 20 40
41 94 41 106 0 26 36 46 84 46 57 0 66 -16 40 -68 -34 -67 -53 -89 -81 -95
-41 -8 -56 -38 -38 -75 14 -26 13 -35 -5 -92 -23 -73 -25 -109 -7 -134 12 -16
24 -18 98 -13 46 3 122 13 169 22 47 8 99 18 117 21 27 5 39 0 73 -29 46 -39
97 -52 151 -37 79 22 241 151 267 213 12 29 16 67 7 67 -11 0 -49 -27 -79 -55
-17 -17 -51 -46 -76 -65 -25 -19 -47 -38 -50 -42 -3 -4 -27 -19 -53 -33 -51
-27 -105 -33 -125 -13 -17 17 -15 94 3 148 22 63 29 79 78 173 7 12 22 36 35
54 12 17 22 36 22 42 0 6 3 11 8 11 4 0 16 14 27 31 18 27 72 69 90 69 7 0 4
-23 -11 -83 -7 -29 9 -57 32 -57 8 0 26 19 41 43 15 23 28 44 30 46 1 2 15 -2
31 -8 22 -9 35 -9 55 0 15 7 27 16 27 20 0 18 75 39 135 39 84 0 82 2 41 -77
-9 -17 -16 -38 -16 -47 0 -9 -4 -16 -10 -16 -5 0 -10 -9 -10 -21 0 -11 -6 -33
-14 -47 -26 -50 -35 -77 -77 -218 -13 -45 -10 -95 9 -134 14 -28 24 -36 51
-38 59 -6 124 83 74 102 -9 4 -19 3 -22 -2 -3 -5 -16 -12 -28 -16 -19 -6 -23
-4 -23 14 0 28 19 111 31 132 5 10 9 24 9 32 0 8 6 27 14 43 8 15 21 44 29 63
9 19 31 70 51 113 20 43 36 82 36 85 1 4 9 17 19 30 17 19 29 22 94 22 59 0
76 -3 81 -16 10 -26 7 -48 -12 -84 -9 -19 -24 -55 -31 -80 -8 -25 -18 -50 -23
-57 -5 -6 -14 -26 -19 -45 -33 -105 -42 -162 -43 -268 -1 -102 1 -115 17 -118
14 -3 17 4 17 37 0 22 4 42 9 45 4 3 11 29 15 58 4 29 11 67 16 83 5 17 17 57
26 90 10 33 21 67 25 75 4 8 20 47 35 85 15 39 38 87 51 107 13 21 23 41 23
46 0 26 39 42 103 42 80 0 84 -9 26 -65 -76 -73 -137 -168 -176 -275 -20 -55
-15 -82 22 -129 21 -26 35 -55 35 -71 0 -15 14 -46 30 -68 28 -40 33 -42 80
-42 118 0 256 150 323 350 36 107 41 156 23 212 -9 30 -26 65 -39 79 -28 33
-83 51 -122 40 -19 -5 -41 -3 -60 4 -83 34 -113 38 -292 36 l-182 -1 -17 25
c-21 32 -51 32 -65 1z m681 -148 c16 -18 20 -35 20 -95 0 -40 -4 -81 -9 -91
-11 -20 -41 -120 -41 -134 0 -11 -92 -141 -118 -168 -52 -53 -122 -67 -143
-28 -14 26 -6 195 10 211 6 6 11 17 11 24 0 19 65 106 91 121 19 12 21 19 15
61 -6 45 -4 49 25 72 30 23 82 47 106 48 7 1 21 -9 33 -21z m-1389 -81 c-7
-24 -30 -62 -52 -86 -39 -42 -41 -43 -60 -25 -18 16 -25 16 -82 5 -102 -21
-111 -22 -126 -12 -11 7 -10 16 8 53 11 24 21 49 21 55 0 5 8 17 18 26 14 13
44 17 140 20 67 2 125 6 128 10 15 14 16 -4 5 -46z m-105 -180 c2 -7 -2 -35
-11 -62 -8 -28 -15 -64 -15 -82 0 -17 -5 -35 -11 -38 -9 -6 -64 -12 -274 -28
-48 -3 -45 39 10 143 9 17 30 30 67 42 107 34 225 47 234 25z"/>
<path d="M19733 6167 c-27 -34 -36 -38 -125 -47 -72 -7 -86 -5 -120 12 -45 23
-95 18 -140 -14 -24 -16 -28 -27 -28 -65 0 -25 -6 -57 -14 -71 -21 -40 -46
-99 -46 -109 0 -5 -9 -28 -20 -51 -11 -22 -20 -46 -20 -51 0 -17 -43 -51 -96
-77 -68 -32 -74 -31 -74 16 0 37 16 79 76 205 32 68 32 117 -1 135 -21 11 -29
11 -52 -1 -33 -17 -72 -55 -96 -92 -10 -16 -27 -38 -37 -50 -11 -12 -25 -31
-33 -41 -28 -38 -37 -9 -35 104 1 115 -6 140 -43 140 -11 0 -29 -6 -41 -12
-31 -18 -108 -100 -108 -115 0 -7 -7 -13 -15 -13 -12 0 -15 11 -13 50 4 87
-37 94 -72 13 -12 -26 -28 -61 -36 -76 -8 -16 -14 -37 -14 -48 0 -11 -4 -27
-9 -37 -4 -9 -16 -42 -26 -72 -10 -30 -25 -60 -34 -67 -22 -18 -115 -63 -129
-63 -15 0 -16 86 -2 95 6 3 10 13 10 21 0 8 18 52 40 98 48 99 52 146 15 166
-42 22 -80 -5 -174 -128 -45 -59 -67 -79 -72 -70 -5 7 -9 63 -9 123 0 115 -6
135 -42 135 -25 0 -56 -19 -92 -57 -46 -48 -56 -61 -56 -73 0 -5 -7 -10 -16
-10 -13 0 -15 8 -10 43 7 51 -14 88 -41 71 -18 -11 -24 -20 -48 -79 -9 -22
-20 -48 -25 -57 -6 -10 -10 -27 -10 -38 0 -10 -6 -34 -14 -52 -21 -51 -36
-102 -36 -125 0 -11 -6 -36 -14 -54 -13 -31 -21 -97 -17 -141 0 -10 11 -22 22
-29 18 -9 25 -7 45 15 13 14 24 29 24 35 0 13 33 75 43 79 4 2 7 12 7 22 0 10
4 22 10 25 5 3 17 23 27 43 20 45 120 210 141 234 25 29 35 10 28 -56 -21
-207 -21 -211 9 -234 33 -26 52 -17 79 39 11 21 23 39 28 39 4 0 8 5 8 10 0
15 63 99 74 100 4 0 5 -15 1 -32 -4 -18 -9 -62 -12 -98 -6 -89 11 -112 84
-112 29 1 61 6 72 11 38 21 44 14 37 -39 -6 -42 -3 -53 14 -70 11 -11 25 -20
30 -20 12 0 80 104 80 123 0 7 4 17 9 23 5 5 29 47 53 94 25 47 65 117 90 156
67 103 95 106 77 8 -5 -27 -10 -86 -12 -131 -2 -65 1 -86 15 -99 17 -18 68
-13 68 7 0 6 11 25 25 43 14 18 25 36 25 40 0 3 17 29 37 56 24 32 41 46 47
40 6 -6 5 -19 -5 -36 -20 -39 -19 -168 2 -190 20 -19 107 -21 137 -3 29 18 56
4 47 -24 -4 -12 -9 -64 -12 -114 -5 -78 -3 -93 10 -93 23 0 35 21 46 80 33
177 30 165 78 305 44 125 81 195 102 195 11 0 32 10 48 23 44 36 79 47 148 47
75 0 76 -2 41 -75 -21 -44 -36 -82 -66 -160 -4 -11 -13 -33 -20 -50 -33 -82
-43 -127 -44 -202 -1 -91 11 -112 67 -113 34 0 77 43 77 77 0 31 -27 41 -45
17 -10 -14 -16 -16 -25 -7 -20 19 -3 116 31 185 5 10 9 22 9 27 0 6 18 49 40
96 22 47 40 90 40 95 0 10 46 97 63 118 6 8 37 12 88 12 86 0 89 -3 68 -57
-20 -50 -27 -67 -41 -113 -27 -83 -36 -115 -43 -150 -4 -19 -11 -39 -15 -45
-4 -5 -11 -29 -14 -52 -3 -24 -9 -61 -12 -82 -5 -35 -3 -41 20 -56 35 -23 46
-13 101 99 93 187 195 356 216 356 13 0 15 -19 11 -150 l-4 -149 26 -7 c28 -7
66 17 66 42 0 7 3 14 8 16 4 2 23 28 42 58 38 61 68 95 77 86 4 -3 1 -16 -5
-29 -10 -22 -19 -54 -29 -113 -3 -19 2 -42 14 -65 18 -32 23 -34 76 -37 40 -2
64 2 79 12 35 25 48 20 48 -18 0 -19 -4 -38 -10 -41 -18 -11 -11 -71 9 -90 17
-14 33 -16 98 -12 75 5 224 29 270 42 16 5 24 1 34 -17 17 -32 30 -39 59 -32
41 9 61 33 48 54 -9 13 -9 29 -1 57 6 21 18 63 27 94 9 31 16 65 16 76 0 26
40 107 48 98 4 -4 10 -37 14 -73 8 -80 30 -187 41 -198 4 -4 7 -17 7 -29 0
-29 30 -55 62 -55 21 0 30 9 52 55 14 31 26 60 26 65 0 6 4 18 10 28 14 26 35
79 65 162 14 41 33 90 41 108 8 18 14 37 14 43 0 14 41 48 58 49 6 0 12 5 12
11 0 18 68 39 130 39 57 0 60 -1 60 -24 0 -13 -11 -46 -24 -73 -26 -54 -66
-152 -91 -228 -9 -27 -21 -58 -26 -67 -5 -10 -9 -51 -9 -91 0 -64 3 -76 23
-95 28 -26 69 -28 97 -5 25 22 29 93 5 93 -9 0 -18 -7 -21 -15 -4 -8 -12 -15
-19 -15 -22 0 -27 35 -10 81 8 24 15 53 15 64 0 12 4 25 8 30 5 6 17 33 27 60
37 103 122 280 141 295 10 9 76 12 243 11 126 -1 236 2 245 5 9 3 16 14 16 24
0 26 -45 32 -273 37 l-198 4 -9 24 c-13 34 -53 34 -68 1 -14 -30 -53 -42 -176
-51 -85 -7 -90 -6 -106 15 -18 24 -40 20 -40 -8 0 -10 -11 -29 -25 -44 -14
-15 -25 -36 -25 -47 0 -18 -18 -67 -41 -113 -5 -10 -9 -22 -9 -28 0 -6 -11
-33 -25 -62 -14 -28 -25 -59 -25 -67 0 -24 -63 -142 -73 -138 -20 6 -37 107
-42 257 -4 85 -8 165 -11 178 -5 23 -37 62 -52 62 -16 0 -50 -35 -53 -55 -4
-19 -15 -20 -204 -26 -221 -6 -321 -22 -331 -51 -9 -30 4 -38 61 -38 58 0 71
-9 56 -37 -5 -10 -17 -36 -27 -58 -24 -50 -38 -65 -62 -65 -18 0 -52 -29 -52
-44 0 -13 -24 -32 -73 -55 -48 -23 -77 -23 -77 2 0 33 56 174 107 270 15 29
10 61 -13 84 -35 36 -94 -4 -183 -123 -44 -58 -59 -74 -73 -74 -6 0 1 210 7
218 2 2 11 5 22 6 10 2 18 11 18 22 0 14 -8 20 -30 22 -16 2 -34 8 -39 13 -6
5 -97 9 -202 9 l-193 0 -19 25 c-25 31 -45 32 -69 2z m347 -110 c0 -17 -48
-87 -60 -87 -13 0 -13 41 0 74 7 19 17 26 35 26 14 0 25 -6 25 -13z m998 -107
c2 -12 -1 -27 -7 -33 -6 -6 -11 -20 -11 -30 0 -20 -27 -109 -41 -139 -5 -10
-9 -26 -9 -35 1 -38 -35 -133 -52 -139 -9 -3 -72 -9 -140 -14 -68 -6 -138 -13
-155 -16 -40 -8 -52 13 -33 54 21 45 38 86 43 104 6 16 95 48 136 48 11 0 40
4 63 10 24 5 55 12 71 15 21 4 27 11 27 31 0 33 -20 39 -88 24 -104 -23 -121
-24 -128 -6 -4 9 7 45 24 81 l31 64 68 3 c37 2 97 2 133 2 59 -1 65 -3 68 -24z"/>
<path d="M11118 6126 c-46 -94 -68 -142 -68 -149 0 -4 -11 -30 -25 -59 -14
-28 -25 -57 -25 -63 0 -11 -20 -59 -41 -97 -5 -10 -9 -23 -9 -30 0 -6 -9 -31
-21 -55 -21 -43 -41 -54 -55 -31 -10 16 -23 147 -30 311 -6 119 -8 128 -30
146 -13 11 -32 17 -42 14 -18 -4 -52 -83 -52 -121 0 -12 -4 -23 -9 -26 -4 -3
-11 -25 -15 -48 -4 -23 -11 -47 -15 -53 -5 -5 -11 -23 -14 -40 -2 -16 -12 -55
-21 -85 -10 -30 -19 -66 -22 -80 -3 -14 -9 -34 -14 -45 -5 -11 -14 -44 -20
-73 -10 -41 -9 -56 0 -67 17 -20 43 -19 72 4 19 15 24 27 20 49 -2 16 2 46 8
68 7 21 16 59 20 84 16 87 36 154 47 157 14 5 31 -46 38 -117 12 -113 50 -218
82 -226 34 -9 50 1 68 43 9 21 23 51 31 66 8 16 14 37 14 48 0 10 5 19 10 19
6 0 10 8 10 18 0 9 7 30 15 46 8 15 15 34 15 41 0 11 20 58 41 97 5 10 9 25 9
33 0 8 4 23 9 33 22 43 41 94 41 109 0 9 5 31 12 49 9 26 9 35 -4 47 -13 13
-16 11 -30 -17z"/>
<path d="M14125 6113 c-10 -21 -28 -60 -41 -86 -13 -27 -24 -53 -24 -58 0 -5
-11 -32 -25 -61 -14 -28 -25 -59 -25 -68 0 -8 -6 -24 -14 -35 -7 -11 -16 -33
-20 -50 -12 -50 -57 -136 -67 -129 -20 12 -32 61 -40 164 -24 321 -22 306 -49
320 -30 16 -58 -4 -66 -45 -3 -19 -10 -38 -15 -41 -5 -3 -9 -16 -9 -29 0 -13
-4 -34 -9 -47 -5 -13 -17 -50 -26 -83 -35 -126 -48 -171 -56 -187 -5 -10 -9
-26 -9 -37 0 -10 -7 -35 -15 -54 -8 -20 -15 -54 -15 -76 0 -53 21 -65 67 -39
30 18 33 24 28 52 -4 17 -1 45 6 61 6 17 15 50 20 75 15 90 32 145 50 169 23
28 24 25 39 -89 19 -148 22 -162 44 -204 17 -33 27 -42 53 -44 34 -3 34 -3 80
108 8 19 18 44 23 55 5 11 16 43 26 70 9 28 21 54 26 60 4 5 8 19 8 31 0 13 4
25 9 29 5 3 13 20 17 37 3 18 10 39 14 48 29 56 63 172 59 195 -7 33 -27 27
-44 -12z"/>
<path d="M8918 6109 c-10 -17 -18 -37 -18 -45 0 -8 -4 -22 -9 -32 -5 -9 -28
-57 -52 -107 -65 -134 -142 -254 -208 -323 -74 -77 -91 -80 -91 -13 0 28 5 53
10 56 6 3 10 18 10 32 0 22 19 91 41 148 4 11 10 31 12 45 3 14 10 39 15 55
39 113 42 159 13 174 -25 14 -46 -4 -61 -54 -6 -22 -15 -49 -20 -60 -5 -11
-11 -31 -13 -45 -6 -28 -25 -86 -38 -112 -5 -10 -9 -28 -9 -40 -1 -13 -7 -34
-15 -48 -8 -14 -14 -42 -15 -62 0 -20 -4 -40 -10 -43 -5 -3 -10 -28 -10 -55 0
-41 5 -53 31 -79 53 -54 106 -36 212 71 40 42 69 64 71 56 2 -7 7 -41 10 -76
6 -69 24 -103 60 -120 20 -9 29 -7 51 10 l27 21 28 -22 c35 -25 38 -26 61 -5
14 13 16 21 8 42 -10 25 -2 80 21 137 4 11 11 34 16 50 12 40 34 38 81 -5 50
-46 109 -90 122 -90 5 0 31 -11 57 -25 45 -24 124 -35 124 -17 0 13 -23 32
-38 32 -23 0 -126 68 -183 121 l-54 49 24 20 c13 11 33 20 45 20 11 1 32 7 46
15 14 8 33 14 42 15 9 0 37 12 62 27 33 20 49 38 60 68 21 54 21 119 -1 140
-80 81 -293 71 -426 -18 -25 -17 -28 -17 -34 -1 -3 9 -1 25 5 35 15 24 15 62
0 77 -20 20 -42 13 -60 -19z m442 -104 c52 -27 31 -92 -42 -127 -70 -33 -181
-62 -193 -50 -11 11 -4 43 20 88 14 26 25 53 25 60 0 41 129 61 190 29z m-290
-57 c0 -17 -24 -86 -33 -94 -4 -4 -7 -15 -7 -25 0 -9 -7 -30 -15 -45 -8 -16
-15 -37 -15 -46 0 -10 -4 -18 -10 -18 -5 0 -10 -10 -10 -22 -1 -13 -7 -34 -15
-48 -8 -14 -14 -37 -15 -52 0 -39 -10 -58 -29 -58 -9 0 -27 -7 -40 -16 -32
-23 -44 -6 -38 53 6 60 25 142 37 165 6 10 10 25 10 33 0 25 41 139 53 147 5
3 24 4 41 1 23 -4 36 1 50 16 22 24 36 27 36 9z"/>
<path d="M9878 6113 c-26 -14 -69 -49 -95 -78 -62 -69 -65 -73 -87 -120 -10
-22 -26 -49 -36 -60 -17 -19 -33 -52 -61 -125 -8 -19 -14 -64 -14 -100 0 -119
57 -185 159 -183 70 1 131 34 236 131 26 23 54 42 62 42 23 0 43 -29 62 -90
24 -79 57 -110 118 -110 46 0 84 17 139 62 36 31 109 131 137 190 53 112 62
150 63 248 0 87 -1 95 -28 128 -39 48 -91 65 -152 51 -45 -11 -129 -66 -182
-119 -39 -39 -109 -138 -109 -154 0 -40 -74 -132 -155 -191 -22 -17 -42 -32
-45 -35 -16 -17 -89 -68 -113 -78 -40 -17 -94 -15 -101 4 -12 31 -6 121 12
169 52 144 208 371 241 351 14 -9 14 -46 1 -82 -8 -19 -7 -30 1 -41 22 -26 53
-11 76 37 39 81 25 140 -40 168 -35 15 -33 15 -89 -15z m587 -99 c21 -23 25
-37 25 -90 0 -34 -4 -65 -9 -68 -5 -3 -11 -20 -14 -38 -10 -63 -31 -123 -56
-163 -40 -62 -43 -65 -98 -122 -43 -43 -59 -53 -87 -53 -63 0 -80 68 -51 210
9 44 83 158 107 166 23 7 34 49 20 79 -10 22 -7 28 26 55 31 26 73 45 105 49
4 1 19 -11 32 -25z"/>
<path d="M16048 6109 c-10 -17 -18 -39 -18 -49 0 -25 -113 -245 -174 -339 -53
-82 -146 -181 -171 -181 -25 0 -29 26 -11 79 9 28 16 59 16 71 0 11 4 28 9 38
8 16 16 43 57 182 8 30 20 65 25 78 16 41 11 100 -10 112 -26 13 -43 -4 -60
-61 -7 -24 -17 -52 -22 -61 -5 -10 -9 -25 -9 -34 0 -9 -6 -30 -14 -47 -7 -18
-21 -59 -31 -92 -9 -33 -21 -70 -27 -82 -6 -12 -11 -64 -12 -116 -1 -89 0 -96
24 -115 51 -43 103 -22 202 78 37 37 69 65 72 62 3 -3 6 -30 6 -60 0 -65 23
-118 59 -136 23 -12 30 -11 61 5 34 18 34 18 56 -2 15 -14 29 -19 43 -14 18 6
21 14 21 71 0 36 7 81 15 101 8 19 15 43 15 53 0 9 9 25 20 35 20 18 21 18 48
-7 15 -13 41 -37 57 -52 48 -42 192 -109 232 -108 20 1 38 4 41 7 7 7 -29 34
-47 35 -20 0 -171 103 -205 140 -26 28 -27 33 -14 46 8 9 30 19 49 24 84 21
161 55 193 87 31 31 50 109 35 150 -8 25 -23 38 -66 58 -48 23 -64 26 -136 22
-97 -5 -143 -16 -214 -53 -55 -29 -76 -27 -55 6 13 21 16 59 6 84 -10 27 -47
19 -66 -15z m447 -106 c45 -22 45 -43 -3 -91 -40 -40 -93 -63 -180 -79 -60
-11 -74 -1 -54 40 26 55 42 96 42 105 0 41 128 57 195 25z m-295 -63 c0 -11
-4 -28 -9 -38 -6 -9 -22 -51 -36 -92 -14 -41 -30 -82 -34 -90 -5 -9 -12 -30
-15 -48 -4 -17 -12 -34 -17 -37 -5 -4 -9 -24 -9 -46 0 -29 -5 -41 -17 -45 -10
-3 -31 -12 -46 -20 -16 -8 -33 -12 -38 -8 -12 7 -12 91 1 99 6 3 10 22 10 41
0 19 7 51 15 71 8 19 15 46 15 59 0 12 5 26 10 29 6 3 10 16 10 28 0 12 9 36
20 54 17 28 23 31 54 26 27 -4 38 -1 52 16 23 26 34 27 34 1z"/>
<path d="M17016 6115 c-60 -29 -144 -117 -185 -195 -18 -33 -36 -62 -42 -65
-24 -16 -79 -170 -79 -224 0 -47 28 -121 57 -148 75 -71 190 -43 329 81 34 31
68 56 75 56 22 0 59 -51 65 -92 4 -24 19 -55 37 -74 26 -30 35 -34 78 -34 114
0 253 154 321 355 38 112 32 232 -15 283 -72 77 -181 55 -307 -62 -67 -62
-130 -150 -130 -181 0 -7 -7 -18 -15 -25 -8 -7 -15 -20 -15 -29 0 -19 -51 -70
-141 -141 -35 -28 -71 -57 -81 -65 -34 -28 -87 -46 -124 -43 l-39 3 -3 63 c-3
60 16 140 40 167 4 5 8 15 8 22 0 21 106 187 153 239 31 34 48 46 60 41 14 -5
16 -13 10 -34 -4 -16 -8 -44 -8 -63 0 -30 3 -35 24 -35 45 0 89 123 62 174 -9
15 -72 54 -85 51 0 0 -23 -11 -50 -25z m578 -96 c24 -19 26 -27 26 -90 0 -38
-4 -77 -9 -87 -12 -24 -41 -117 -41 -131 0 -24 -84 -143 -132 -186 -39 -35
-58 -45 -85 -45 -29 0 -36 5 -49 35 -31 75 -9 178 61 288 16 26 35 47 42 47
21 0 35 39 28 80 -6 35 -4 40 29 65 29 22 69 40 97 44 4 1 18 -8 33 -20z"/>
<path d="M8215 6097 c-22 -6 -42 -14 -45 -17 -3 -3 -22 -16 -42 -29 -61 -37
-155 -149 -198 -236 -50 -101 -55 -166 -15 -209 14 -15 25 -36 25 -46 0 -28
31 -92 58 -117 28 -28 93 -31 143 -6 83 40 189 180 233 307 13 37 26 70 30 73
3 4 6 46 6 95 0 77 -3 93 -25 129 -36 56 -97 76 -170 56z m84 -66 c24 -13 45
-82 37 -123 -4 -18 -10 -49 -13 -68 -3 -19 -9 -39 -13 -45 -4 -5 -11 -26 -14
-45 -7 -33 -25 -79 -36 -90 -3 -3 -17 -23 -32 -45 -78 -117 -153 -161 -200
-118 -14 13 -18 31 -18 86 0 112 30 187 104 260 48 46 49 49 38 81 -7 18 -12
35 -12 39 0 10 61 50 99 63 44 16 39 16 60 5z"/>
<path d="M15350 6096 c-53 -17 -97 -43 -140 -82 -42 -38 -140 -165 -140 -181
0 -6 -11 -31 -25 -57 -34 -65 -34 -135 0 -167 14 -14 25 -35 25 -51 0 -15 5
-30 10 -33 6 -3 10 -14 10 -24 0 -9 14 -32 31 -49 58 -59 143 -40 235 52 54
55 100 123 132 196 43 99 52 136 52 221 0 79 -2 89 -29 126 -22 31 -40 43 -74
52 -25 6 -47 11 -49 10 -2 -1 -19 -6 -38 -13z m81 -66 c10 -6 19 -17 19 -25 0
-8 5 -15 10 -15 6 0 10 -21 10 -47 0 -44 -7 -76 -32 -148 -6 -16 -13 -47 -15
-67 -3 -21 -9 -38 -14 -38 -5 0 -9 -5 -9 -11 0 -5 -24 -43 -54 -83 -78 -105
-143 -140 -188 -99 -14 12 -18 31 -18 81 0 106 44 211 114 274 39 36 40 38 28
71 -6 18 -12 36 -12 39 0 14 89 64 138 77 2 1 12 -4 23 -9z"/>
<path d="M7806 6041 c-9 -10 -16 -22 -16 -27 0 -14 -109 -117 -156 -146 -42
-27 -55 -31 -86 -29 -26 2 -24 70 3 117 18 32 19 41 8 61 -34 64 -99 -19 -99
-127 0 -56 4 -69 25 -93 22 -23 34 -27 79 -27 49 0 60 5 131 55 42 30 81 53
86 50 5 -4 9 -22 9 -42 0 -26 -8 -42 -31 -64 -17 -16 -36 -29 -42 -29 -18 0
-237 -112 -237 -121 0 -5 -6 -9 -14 -9 -8 0 -42 -26 -75 -58 -51 -48 -61 -63
-61 -90 0 -48 15 -70 59 -83 34 -10 49 -8 125 16 47 15 86 31 86 36 0 5 26 27
58 50 31 23 68 54 81 68 33 37 91 127 91 144 0 7 5 18 12 25 16 16 29 98 35
214 l4 97 -29 15 c-27 14 -31 14 -46 -3z m-115 -420 c-36 -51 -108 -107 -184
-145 -85 -41 -107 -45 -107 -16 0 37 87 104 218 170 84 42 107 40 73 -9z"/>
<path d="M14440 5732 c0 -26 6 -32 40 -46 56 -22 202 -22 235 0 14 9 25 22 25
30 0 17 -24 23 -130 28 -47 3 -104 8 -127 11 -43 7 -43 7 -43 -23z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
